<template>
  <b-modal id="modalSignings" :title="`${tournament.name} - ${$t('signings')}`" no-close-on-backdrop>
    <p v-if="tournament.lock_signing"><strong>{{ $t('transfersClosedMsg') }}</strong></p>
    <p v-else><strong>{{ $t('transfersOpenedMsg') }}</strong></p>
    <div slot="modal-footer">
      <b-button @click="$root.$emit('bv::hide::modal', 'modalSignings')">{{$t('cancel')}}</b-button>
      <b-button v-if="tournament.lock_signing" variant="primary" @click="save" class="ml-3 text-uppercase">{{$t('open')}} {{ $t('signings') }}</b-button>
      <b-button v-else variant="primary" @click="save" class="ml-3 text-uppercase">{{$t('close')}} {{ $t('signings') }}</b-button>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      title: '',
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'tournament'
    ])
  },
  methods: {
    save () {
      const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournament.id}/open-close-signings`
      const payload = {
        status: !this.tournament.lock_signing
      }
      this.$axios.post(path, payload).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        const data = {
          id: this.tournament.id,
          status: payload.status
        }
        this.$store.dispatch('SET_TOURNAMENT_SIGNINGS', data)
        this.$root.$emit('bv::hide::modal', 'modalSignings')
      })
    }
  }
}
</script>
